
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

const numberOfShownFirstLastPages = 3;

export default class Pagination extends Vue {
  @Prop({ required: true }) private currentPage!: number;

  @Prop({ required: true }) private itemsPerPage!: number;

  @Prop({ required: true }) private itemsTotal!: number;

  showPagination(): boolean {
    if (this.totalPages() > 1) {
      return true;
    }
    return false;
  }

  totalPages(): number {
    return Math.ceil(this.itemsTotal / this.itemsPerPage);
  }

  pagesShownInPagination(): number[] {
    const pages: number[] = [];
    for (let i = 0; i < this.totalPages(); i += 1) {
      if (i < numberOfShownFirstLastPages || (Math.abs(i - this.currentPage) <= 1) || (i >= this.totalPages() - numberOfShownFirstLastPages)) {
        pages.push(i);
      }
    }
    return pages;
  }

  onSelectPage(page: number): void {
    if (page === this.currentPage) {
      return;
    }
    this.$emit('pageSelection', page);
  }
}
