import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "items-content" }
const _hoisted_2 = {
  key: 0,
  class: "w-100 d-flex justify-content-center"
}
const _hoisted_3 = {
  key: 1,
  class: "my-3"
}
const _hoisted_4 = {
  key: 2,
  class: "my-3"
}
const _hoisted_5 = {
  key: 3,
  class: "w-100 d-flex justify-content-center my-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_item = _resolveComponent("item")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_loader)
        ]))
      : _createCommentVNode("", true),
    (_ctx.items && !_ctx.items.length && _ctx.isCategorySelected)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Δεν υπάρχουν αντικείμενα"))
      : _createCommentVNode("", true),
    (!_ctx.isCategorySelected)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Επιλέξτε κατηγορία"))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_item, {
        class: "item",
        key: item.id,
        item: item,
        onClick: ($event: any) => (_ctx.onSelectItem(item.id))
      }, null, 8, ["item", "onClick"]))
    }), 128)),
    (_ctx.$store.getters.getItemsPerPage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_pagination, {
            currentPage: _ctx.pagination.currentPage,
            itemsTotal: _ctx.pagination.itemsCount,
            itemsPerPage: _ctx.$store.getters.getItemsPerPage,
            onPageSelection: _ctx.onSelectPage
          }, null, 8, ["currentPage", "itemsTotal", "itemsPerPage", "onPageSelection"])
        ]))
      : _createCommentVNode("", true)
  ]))
}