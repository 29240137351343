import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65a31c8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "pagination d-flex align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "mx-2"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "page-link" }
const _hoisted_6 = {
  key: 1,
  class: "mx-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showPagination())
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pagesShownInPagination(), (page, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
              (page - _ctx.pagesShownInPagination()[i - 1] > 1)
                ? (_openBlock(), _createElementBlock("li", _hoisted_3, "..."))
                : _createCommentVNode("", true),
              _createElementVNode("li", {
                onClick: ($event: any) => (_ctx.onSelectPage(page)),
                class: _normalizeClass(["page-item", {'active': _ctx.currentPage === page}])
              }, [
                _createElementVNode("a", _hoisted_5, _toDisplayString(page + 1), 1)
              ], 10, _hoisted_4),
              (page - _ctx.pagesShownInPagination()[i - 1] < 1)
                ? (_openBlock(), _createElementBlock("li", _hoisted_6, "..."))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}