import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01a0d4fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-card card my-2 bg-white" }
const _hoisted_2 = { class: "d-flex align-items-center mx-3 my-2" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "flex-grow-1 text-center",
        style: _normalizeStyle({'margin-left': _ctx.item.thumbnail ? '70px !important' : '0'})
      }, _toDisplayString(_ctx.item.title), 5),
      (_ctx.item.thumbnail)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            style: {"border-radius":"50%"},
            height: "70",
            width: "70",
            src: _ctx.item.thumbnail,
            alt: "item thumbnail"
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ])
  ]))
}