
import { Options, Vue } from 'vue-class-component';
import SideMenu from '@/components/SideMenu.vue';
import Content from '@/components/Content.vue';

@Options({
  components: { SideMenu, Content },
})
export default class Collection extends Vue {
  selectedCategoryId: number | null = null;

  itemsNum: number | null = null;

  onSelectCategory(id: number) {
    this.selectedCategoryId = id;
    console.log('selected category: ', id);
  }
}
