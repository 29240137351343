import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23ae2ab0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "side-menu my-3 ms-2 border rounded" }
const _hoisted_2 = {
  key: 0,
  class: "w-100 d-flex justify-content-center"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "list-group" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.categories === null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_loader)
        ]))
      : _createCommentVNode("", true),
    (_ctx.categories !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["list-group-item", {'bg-primary': _ctx.selectedCategoryId === category.id, 'text-white': _ctx.selectedCategoryId === category.id, 'bg-white': _ctx.selectedCategoryId !== category.id, 'text-dark': _ctx.selectedCategoryId !== category.id}]),
                key: category.id,
                onClick: ($event: any) => (_ctx.onSelectCategory(category.id))
              }, _toDisplayString(category.label), 11, _hoisted_5))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}