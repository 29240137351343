
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ApiCalls from '@/services/api-calls';
import Item from '@/components/Item.vue';
import Pagination from '@/components/Pagination.vue';
import Loader from '@/components/Loader.vue';
import { ItemDisplay } from '@/models';
import store from '@/store';

@Options({
  components: { Item, Pagination, Loader },
})
export default class Content extends Vue {
  @Prop({ required: true }) private selectedCategoryId!: number;

  api = new ApiCalls();

  pagination = {
    currentPage: 0,
    itemsCount: 0,
  };

  items: ItemDisplay[] | null = [];

  isLoading = false;

  isCategorySelected = false;

  @Watch('selectedCategoryId')
  onSelectedCategoryIdChange(): void {
    this.loadItems();
  }

  loadItems(page = 0) {
    this.items = null;
    this.isLoading = true;
    this.isCategorySelected = true;

    console.log(this.selectedCategoryId, store.getters.getItemsPerPage);
    this.api.getItemsByResourceTemplate(this.selectedCategoryId, store.getters.getItemsPerPage, page).then((response) => {
      console.log('qwe', response);
      this.pagination = {
        currentPage: page,
        itemsCount: response.itemsCount,
      };
      this.items = response.items.map((x: any) => ({
        id: x['o:id'],
        title: x['o:title'],
        thumbnail: x.thumbnail_display_urls.square,
      }));
      this.$emit('itemsCountChange', response.itemsCount);

      this.isLoading = false;

      // this.$nextTick(() => {
      //   const y = (document.querySelectorAll('.items-content .item-card')[0] as HTMLElement).offsetTop;
      //   window.scrollTo({
      //     top: y,
      //     behavior: 'smooth',
      //   });
      // });
    });
  }

  onSelectPage(page: number) {
    this.loadItems(page);
  }

  onSelectItem(id: number): void {
    this.$router.push(`/item/${id}`);
  }
}
