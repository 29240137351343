
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ApiCalls from '@/services/api-calls';
import Loader from '@/components/Loader.vue';
import store from '@/store';

@Options({
  components: { Loader },
})
export default class SideMenu extends Vue {
  api = new ApiCalls();

  // categories: { id: number, label: string }[] | null = null;
  categories: { id: number, label: string, properties: { id: number, label: string }[] } | null = null;

  selectedCategoryId: number | null = null;

  created(): void {
    this.categories = store.getters.getCategories;
  }

  @Watch('$store.getters.getCategories', { immediate: true, deep: true })
  onCategoriesInit(categories: any): void {
    this.categories = categories;
  }

  onSelectCategory(id: number): void {
    this.selectedCategoryId = id;
    this.$emit('selectCategory', id);
  }
}
